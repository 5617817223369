<template>
  <div>
    <v-container class="mt-9">
      <v-subheader class="title mb-4 text-uppercase pa-0">
        <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon>
        {{ "Gerenciar Escalas dos Motoristas" }}
      </v-subheader>
      <v-card flat outlined>
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            flat
            solo
            :disabled="items.length == 0 ? true : false"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            dark
            class="mb-2"
            to="/escalas/motoristas/new"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            depressed
            dark
            class="mb-2 ml-2"
            @click="initialize"
          >
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-0"
        >
          <template v-slot:item.data="{ item }">
            <span>{{ convertDate(item.data) }}</span>
          </template>

          <template v-slot:item.action="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editar(item.codigo)"
              color="primary"
              >mdi-pencil</v-icon
            >
            <v-icon small @click="deleteItem(item.codigo)" color="error"
              >mdi-delete</v-icon
            >
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" depressed @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    headers: [
      { text: "Data", value: "data", sortable: false },
      { text: "Ônibus", value: "descricao", sortable: false },
      { text: "Viagem", value: "viagem", sortable: false },
      { text: "Motorista", value: "motorista", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    items: [],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http.post("/escalas/motoristas").then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    editar(id) {
      this.$router.push(`/escalas/motoristas/editar/${id}`);
    },
    deleteItem(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/escalas/delete/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          this.initialize();
        });
      }
    },
  },
};
</script>

